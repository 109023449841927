.img-width {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.about-img {
  width: 80%;
  border-radius: 30px;
}
/*  */
.contact-container {
  display: flex;
  width: 100%;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  /* border-radius: 20px; */
  overflow: hidden;
}

.left-half {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: lightblue;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.form-group textarea {
  height: 120px;
  resize: none;
}

.submit-btn {
  width: 20%;
  padding: 12px;
  border: none;
  border-radius: 14px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}
/* body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
} */

.about-container {
  width: 100%;
  height: auto;
  padding: 40px 40px 60px 40px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  align-items: center;
}
.about-cont1 {
  width: 60%;
  
}
.about-cont2 {
  width: 50%;
  
}
h1 {
  font-size: 3em;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.6;
}

.footer {
  width: 100%;
  /* margin: 40px 0; */
  padding: 20px;
  background-color: #333;
  color: white;

  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.footer h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #fff;
}

.footer-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-links a {
  color: #ccc;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #fff;
}

.social-media {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-media a {
  color: #ccc;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #fff;
}

/* Social Media Icons */
.social-media a .fab {
  font-size: 1.5em;
}
.navbar-custom {
  background-color: #00334e; /* Dark blue color */
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: #fff; /* White text */
}

.navbar-custom .nav-link:hover {
  color: #ddd; /* Lighter color on hover */
}

